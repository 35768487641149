import axios, { baseURL } from '@axios'
import { paginateArray, sortCompare } from '@/common'
import { getUserData } from "@/auth/utils";
import router from '@/router'
export default {
  namespaced: true,
  state: {
   
  },
  getters: {},
  mutations: {},
  actions: {
     // New
     fetchMyProducts(ctx,  queryParams) {
      const userData = getUserData()
      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'id',
        sortDesc = false,
      } = queryParams
      return new Promise((resolve, reject) => {
        axios
        .get(`${baseURL}/api/SubInventory/GetSubInventoryByUserGroup?UserId=${router.currentRoute.params.id}`,  
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response =>{
           
            resolve({
              data: paginateArray(response.data.data, perPage, page),
              total: response.data.data.length,
            })
          })
          .catch(error => reject(error))
      })
      },
      fetchMyTranfer(ctx,  queryParams) {
        const userData = getUserData()
        const {
          q = '',
          perPage = 10,
          page = 1,
          sortBy = 'id',
          sortDesc = false,
        } = queryParams
        return new Promise((resolve, reject) => {
          axios
          .get(`${baseURL}/api/SubInventory/GetTransferHistory?userId=${router.currentRoute.params.id}`)
            .then(response =>{
              resolve({
                data: paginateArray(response.data, perPage, page),
                total: response.data.length,
              })
            })
            .catch(error => reject(error))
        })
        },
      fetchSubInventoryByExchange(ctx, { id }) {
        return new Promise((resolve, reject) => {
          axios
            .get(`${baseURL}/api/SubInventory/GetListSubInventoryByExchangeGroup`, { 
              params: {
               productId: id
              }
            })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      
      fetchGetListSubInventoryByUserId(ctx) {
        return new Promise((resolve, reject) => {
          axios
          .get(`${baseURL}/api/SubInventory/GetSubInventoryByUserGroup?UserId=${router.currentRoute.params.id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },

      fetchUserTranfer(ctx, { id }) {
        return new Promise((resolve, reject) => {
          axios
            .get(`${baseURL}/api/User/GetUserTransferById?userId=${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      addExchangeSub(ctx, product) {
        const userData = getUserData()
  
        return new Promise((resolve, reject) => {
          axios
            .post(`${baseURL}/api/SubInventory/ExchangeSubInventory`, product, {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${userData.token}`,
              },
            })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },


  
    fetchUserList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
         const {
              q = '',
              perPage = 10,
              page = 1,
              sortBy = 'id',
              sortDesc = false,
            } = queryParams
            const payload = {
              key: q,
              dateFrom: null,
              dateTo: null,
              status: null
            }
        axios
          .post(`${baseURL}/api/User/GetByKey`, payload)
          .then(response => {
           
            resolve({
              data: paginateArray(response.data.data, perPage, page),
              total: response.data.data.length,
            })
          }
           )
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/User/GetById`, { 
            params: {
              UserId: id
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addUser(ctx, product) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/User/Add`, product, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateUser(ctx, product) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/User/Update`, product, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getRoleAll() {
      const userData = getUserData()
    
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Role/GetByKey`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


    // old product

    fetchProducts(ctx, queryParams) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        const body = {
          pagination: {
            total: 0,
            current: 0,
            pageSize: 0,
            // current: queryParams.page,
            // pageSize: queryParams.perPage,
          },
          search: {
            name: queryParams.q,
            // name: '',
            startPrice: null,
            endPrice: null,
            shopId: userData.shopId,
            categoryId: null,
            hasOptions: null,
            isVisibleIndividually: null,
            isFeatured: null,
            isAllowToOrder: null,
            isPublished: null,
            isChecking: null,
            isViolation: null,
            sku: null,
            categoryIds: [

            ],
            includeSubCategories: true,
            outOfStock: null,
          },
          sort: {
            predicate: 'product_name',
            // predicate: queryParams.sortBy,
            reverse: true,
          },
        }

        axios
          .post(`${baseURL}/api/Product/GetList`, body, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => {
            const {
              q = '',
              perPage = 10,
              page = 1,
              sortBy = 'id',
              sortDesc = false,
              productName = null,
              productCategory = null,
              status = null,
            } = queryParams

            const filteredData = response.data.data.list

            resolve({
              products: paginateArray(filteredData, perPage, page),
              total: filteredData.length,
            })
          })
          .catch(error => reject(error))
      })
    },

    getProduct(ctx, { id }) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Product/GetById`, {
            params: {
              id,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
  
      })
    },
   
    uploadFile(ctx, formData) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Upload/Upload`, formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userData.token}`,
              },
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadFileMultiple(ctx, formData) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Upload/MultiUpload/multiple`, formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userData.token}`,
              },
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  

    
  },
}
