<template>
  <div>
    <b-breadcrumb class="breadcrumb-slash mb-1">
      <b-breadcrumb-item :to="{ name: 'admin-members-list' }">
        รายการสมาชิก
      </b-breadcrumb-item>
      <b-breadcrumb-item active>
        {{ isEdit ? "รายละเอียดสมาชิก" : "เพิ่มสมาชิก" }}
      </b-breadcrumb-item>
    </b-breadcrumb>

    <validation-observer ref="simpleRules">
      <b-form>
        <b-card>
          <div style="display: flex; justify-content: space-between">
            <b-card-title class="pt-50"> รายละเอียดสมาชิก </b-card-title>
            <b-button
              v-if="isEdit"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-edit-product
              variant="outline-primary"
            >
              แก้ไข
            </b-button>
          </div>

          <hr />
          <b-row v-if="isEdit">
            <b-col md="6">
              <b-form-group
                label="หมายเลขสมาชิก :"
                label-cols="4"
                label-for="name"
              >
                <validation-provider #default="{ errors }" name="userId">
                  <b-form-input
                    id="userId"
                    disabled
                    v-model="userModel.userCode"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="ชื่อ - นามสกุล :"
                label-cols="4"
                label-for="fullname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="fullname"
                  rules="required"
                >
                  <b-form-input
                    id="fullname"
                    v-model="userModel.fullname"
                    :disabled="isEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="ชื่อผู้ใช้งาน :"
                label-cols="4"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="userModel.username"
                    :disabled="isEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="เบอร์โทรศัพท์ :"
                label-cols="4"
                label-for="phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  rules="required|min:10"
                >
                  <b-form-input
                    id="phone"
                    v-model="userModel.phone"
                    :disabled="isEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6" v-if="!isEdit">
              <b-form-group
                label="รหัสผ่าน  :"
                label-cols="4"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="!isEdit">
            <b-col md="6">
              <b-form-group
                label="ยืนยันรหัสผ่าน  :"
                label-cols="4"
                label-for="confirmpassword"
              >
                <validation-provider
                  #default="{ errors }"
                  name="confirmpassword"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldTypes"
                      name="login-password"
                      v-model="confirmPassword"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIconTwo"
                        @click="togglePasswordVisibilityTwo"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- <b-row>
            <b-col md="12">
              <b-form-group
                label="รายะเอียดที่อยู่ :"
                label-cols="2"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input id="name" :disabled="isEdit"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="แขวง/ ตำบล :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <v-select
                    v-model="selectedCategory"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="optionsCategory"
                    :disabled="isEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="เขต/ อำเภอ :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <v-select
                    v-model="selectedCategory"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="optionsCategory"
                    :disabled="isEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="จังหวัด :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <v-select
                    v-model="selectedCategory"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="optionsCategory"
                    :disabled="isEdit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="รหัสไปรษณีย์ :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                
                >
                  <b-form-input id="name" :disabled="isEdit"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row> -->

          <b-row>
            <b-col md="6">
              <b-form-group
                label="ประเภทผู้ใช้งาน :"
                label-cols="4"
                label-for="roleName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="roleName"
                  rules="required"
                >

                  <b-form-input
                    id="roleName"
                    disabled
                    v-model="userModel.roleName"
                  />
                  
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="ประเภทผู้ใช้งานย่อย :"
                label-cols="4"
                label-for="userType"
              >
                <validation-provider
                  #default="{ errors }"
                  name="userType"
                  rules="required"
                >
                  <!-- <b-form-input
                    id="userType"
                    disabled
                    v-model="userModel.userType"
                  /> -->

                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="userType"
                    :options="userTypeOptions"
                    :disabled="isEdit"
                    placeholder="-- เลือก --"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="สถานะการใช้งาน :"
                label-cols="4"
                label-for="name"
              >
                <validation-provider #default="{ errors }" name="Name">
                  <b-form-checkbox
                    checked="true"
                    name="check-button"
                    switch
                    inline
                    :disabled="isEdit"
                    v-model="userModel.active"
                  >
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-card-footer
            class="d-flex align-items-center justify-content-end"
            v-if="!isEdit"
          >
            <div class="display: block">
              <p style="color: red">{{ errorMessage }}</p>
              <b-button
                type="submit"
                variant="primary"
                @click.prevent="validationForm"
                style="width: 15rem"
              >
                <!-- <b-spinner
                v-if="isShowLoading"
                small
                class="mr-1"
              /> -->
                เพิ่มสมาชิก
              </b-button>
            </div>
          </b-card-footer>
        </b-card>

        <b-card v-if="isEdit">
          <orders-list :selectedRole="selectedRole.label" />

          <!-- <b-card-footer class="d-flex align-items-center justify-content-end">
            <b-button
              :to="{ name: 'admin-members-list' }"
              variant="danger"
              style="width: 10rem"
              class="mr-1"
            >
              กลับ
            </b-button>
         
          </b-card-footer> -->
        </b-card>

        <b-card v-if="isEdit">
          <product-tranfer-lists :selectedRole="selectedRole.label" />

        
        </b-card>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-edit-product"
      centered
      title="แก้ไขรายละเอียดสมาชิก"
      ok-only
      ok-title="ตกลง"
      hide-footer
      size="xl"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="หมายเลขสมาชิก :"
                label-cols="4"
                label-for="name"
              >
                <validation-provider #default="{ errors }" name="userId">
                  <b-form-input
                    id="userId"
                    disabled
                    v-model="userModel.userCode"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="ชื่อ - นามสกุล :"
                label-cols="4"
                label-for="fullname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="fullname"
                  rules="required"
                >
                  <b-form-input id="fullname" v-model="userModel.fullname" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="ชื่อผู้ใช้งาน :"
                label-cols="4"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    disabled
                    v-model="userModel.username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="เบอร์โทรศัพท์ :"
                label-cols="4"
                label-for="phone"
              >
                <validation-provider #default="{ errors }" name="phone">
                  <b-form-input id="phone" disabled v-model="userModel.phone" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="ประเภทผู้ใช้งาน :"
                label-cols="4"
                label-for="roleName"
              >
                <validation-provider #default="{ errors }" name="roleName">
                  <b-form-input
                    id="roleName"
                    disabled
                    v-model="userModel.roleName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="ประเภทผู้ใช้งานย่อย :"
                label-cols="4"
                label-for="userType"
              >
                <validation-provider #default="{ errors }" name="userType">
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="userType"
                    :options="userTypeOptions"
                    placeholder="-- เลือก --"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="รหัสผ่านเดิม  :"
                label-cols="4"
                label-for="password"
              >
                <validation-provider #default="{ errors }" name="password">
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="userModel.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldTypeth"
                      name="login-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIconThree"
                        @click="togglePasswordVisibilityThree"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="รหัสผ่านใหม่  :"
                label-cols="4"
                label-for="password"
              >
                <validation-provider #default="{ errors }" name="password">
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldTypefo"
                      name="login-password"
                      v-model="confirmEditPass"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIconFour"
                        @click="togglePasswordVisibilityFour"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="ยืนยันรหัสผ่านใหม่  :"
                label-cols="4"
                label-for="name"
              >
                <validation-provider #default="{ errors }" name="Name">
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldTypefi"
                      name="login-password"
                      v-model="editConfirmPass"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIconFive"
                        @click="togglePasswordVisibilityFive"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="สถานะการใช้งาน :"
                label-cols="4"
                label-for="name"
              >
                <validation-provider #default="{ errors }" name="Name">
                  <b-form-checkbox
                    checked="true"
                    name="check-button"
                    switch
                    inline
                    v-model="userModel.active"
                  >
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- <b-row>
            <b-col md="12">
              <b-form-group
                label="รายะเอียดที่อยู่ :"
                label-cols="2"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input id="name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="แขวง/ ตำบล :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <v-select
                    v-model="selectedCategory"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="optionsCategory"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="เขต/ อำเภอ :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <v-select
                    v-model="selectedCategory"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="optionsCategory"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="จังหวัด :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <v-select
                    v-model="selectedCategory"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="optionsCategory"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="รหัสไปรษณีย์ :"
                label-cols="4"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                
                >
                  <b-form-input id="name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row> -->

          <div class="display: block">
            <div class="d-flex align-items-center justify-content-end">
              <p style="color: red">{{ errorMsg }}</p>
            </div>
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                type="submit"
                variant="primary"
                @click.prevent="validationForm"
                style="width: 15rem"
              >
                บันทึก
              </b-button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BLink,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BForm,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardFooter,
  BFormCheckbox,
  BFormDatepicker,
  BFormRadioGroup,
  BFormTextarea,
  BBreadcrumb,
  BBreadcrumbItem,
  BModal,
  VBModal,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Cleave from "vue-cleave-component";
import { ref, onUnmounted } from "@vue/composition-api";
import { stringToNumber } from "@/common";

import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import {
  togglePasswordVisibility,
  togglePasswordVisibilityTwo,
  togglePasswordVisibilityThree,
  togglePasswordVisibilityFour,
  togglePasswordVisibilityFive,
} from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// eslint-disable-next-line import/no-cycle
import router from "@/router";
import store from "@/store";
import productStoreModule from "./productStoreModule";
import OrdersList from "./OrdersList.vue";
import ProductTranferLists from "./ProductTranferLists.vue";
import axios, { baseURL } from "@axios";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    vSelect,
    Cleave,
    BFormTextarea,
    BBreadcrumb,
    BBreadcrumbItem,
    // BTable,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardFooter,
    BFormCheckbox,
    BFormDatepicker,
    // BFormRadioGroup,
    // BLink,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,

    OrdersList,
    ProductTranferLists,
    BModal,
    VBModal,
  },
  mixins: [
    togglePasswordVisibility,
    togglePasswordVisibilityTwo,
    togglePasswordVisibilityThree,
    togglePasswordVisibilityFour,
    togglePasswordVisibilityFive,
  ],
  data() {
    return {
      // validation rules
      required,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      dialogImageUrl: "",
      dialogVisible: false,
      optionsTest: [{ value: 1, text: "test" }],
      minDate: new Date(),
      isError: false,
      errorMessage: "",
      confirmPassword: "",
      isEditError: false,
      errorMsg: "",
      editConfirmPass: "",
      confirmEditPass: "",
      oldPass: "",
      userType: null,
      userTypeOptions: [],
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup() {
    if (!store.hasModule("user-list"))
      store.registerModule("user-list", productStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule("user-list")) store.unregisterModule("user-list");
    });

    const categoryOptions = [{ label: "", value: "" }];

    const sizeOptions = [{ label: "", value: "" }];

    const colorOptions = [{ label: "", value: "" }];

    const shippings = ref([]);
    const selectedCategory = ref({});
    const selectedBox = ref({});
    const optionsCategory = ref([]);

    const fileListBanner = ref([]);

    const userModel = ref({
      userId: "",
      userCode: "",
      username: "",
      password: "",
      fullname: "",
      firstName: "",
      lastName: "",
      phone: "",
      mobile: "",
      roleId: 0,
      roleName: "ตัวแทนขาย",
      token: "",
      active: true,
      userModel: "",
    });

    const isEdit = ref(false);

    const optionsRole = ref([]);
    store.dispatch("user-list/getRoleAll").then((response) => {
      if (response !== null) {
        response.data.data.forEach((data) => {
          optionsRole.value.push({
            value: data.roleId,
            label: data.roleName,
          });
        });
      }
    });
    const selectedRole = ref({});

    if (router.currentRoute.name === "admin-members-edit") {
      isEdit.value = true;
      store
        .dispatch("user-list/fetchUser", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          userModel.value = {
            userId: response.data.userId,
            userCode: response.data.userCode,
            username: response.data.username,
            password: response.data.password,
            fullname: response.data.fullname,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            phone: response.data.phone,
            mobile: response.data.mobile,
            roleId: response.data.roleId.roleId,
            roleName: response.data.roleId.roleName,
            token: response.data.token,
            active: response.data.active,
            userType: response.data.userType,
          };
          selectedRole.value = {
            value: response.data.roleId.roleId,
            label: response.data.roleId.roleName,
          };
        })
        .catch((error) => {
          if (error.response.status === 404) {
          }
        });
    }

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        // productModel.value.avatar = base64;
      }
    );

    return {
      shippings,

      categoryOptions,
      sizeOptions,
      colorOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      fileListBanner,
      optionsCategory,
      selectedCategory,
      selectedBox,
      userModel,
      isEdit,
      optionsRole,
      selectedRole,
    };
  },
  mounted() {
    setTimeout(() => {
      this.GetUserTypeOption();
    }, 1500);
    
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconTwo() {
      return this.passwordFieldTypes === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconThree() {
      return this.passwordFieldTypeth === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconFour() {
      return this.passwordFieldTypefo === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconFive() {
      return this.passwordFieldTypefi === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    GetUserTypeOption() {
      axios
        .get(`${baseURL}/api/User/GetUserType`)
        .then((response) => {
          console.log(response.data.data);
          

          this.userTypeOptions = [];

          response.data.data.forEach((data) => {
            this.userTypeOptions.push({
              label: data.roleName,
              value: data.roleName,
            });
          });

          console.log("userModel", this.userModel);

          this.userType = this.userModel.userType;

        })
        .catch((error) => {
          console.log(error);
        });
    },

    showToast(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon,
          text,
          variant,
        },
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file) {},
    handleChange(file, fileList) {
      let formData = new FormData(); // Create FormData for upload file

      formData.append(file.uid, file.raw, file.name); // Add file for upload

      // store
      //   .dispatch("product/uploadFileMultiple", formData)
      //   .then((res) => {
      //     if (res.status === 200) {
      //       this.productModel.mediaIds.push(res.data.data[0].id);
      //     }
      //   })
      //   .catch((error) => {
      //     this.showToast("danger", "XCircleIcon", error);
      //   });
    },
    handleChangeBanner(file, fileList) {
      this.fileListBanner = [
        {
          name: "",
          url: file.url,
          raw: null,
          status: "success",
        },
      ];
      let formData = new FormData(); // Create FormData for upload file

      formData.append(file.uid, file.raw, file.name); // Add file for upload

      // store
      //   .dispatch("product/uploadFileMultiple", formData)
      //   .then((res) => {
      //     if (res.status === 200) {
      //       this.productModel.thumbnailImageId = res.data.data[0].id;
      //     }
      //   })
      //   .catch((error) => {
      //     this.showToast("danger", "XCircleIcon", error);
      //   });
    },
    setRole(item) {
      if (item !== null) {
        this.userModel.roleId = item.value;
        this.userModel.roleName = item.label;
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        this.isError = false;
        this.isEditError = false;
        if (success) {
          var saveModel = {};
          if (router.currentRoute.name === "admin-members-edit") {
            if (this.editConfirmPass == this.confirmEditPass) {
              if (this.userModel.password && this.editConfirmPass) {
                saveModel = {
                  userId: this.userModel.userId,
                  userCode: this.userModel.userCode,
                  username: this.userModel.username,
                  oldPassword: this.userModel.password,
                  newPassword: this.editConfirmPass,
                  fullname: this.userModel.fullname,
                  firstName: this.userModel.firstName,
                  lastName: this.userModel.lastName,
                  phone: this.userModel.phone,
                  mobile: this.userModel.mobile,
                  roleId: this.userModel.roleId,
                  userType: (this.userType == null ? this.userModel.userType : this.userType.value) || this.userModel.userType, // 'แอดมิน'

                  // roleName: this.userModel.roleName,
                  // token: this.userModel.token,
                  activeUser: this.userModel.active,
                };
              } else {
                saveModel = {
                  userId: this.userModel.userId,
                  userCode: this.userModel.userCode,
                  username: this.userModel.username,
                  oldPassword: null,
                  newPassword: null,
                  fullname: this.userModel.fullname,
                  firstName: this.userModel.firstName,
                  lastName: this.userModel.lastName,
                  phone: this.userModel.phone,
                  mobile: this.userModel.mobile,
                  userType: (this.userType == null ? this.userModel.userType : this.userType.value) || this.userModel.userType, // 'แอดมิน'

                  roleId: this.userModel.roleId,
                  // roleName: this.userModel.roleName,
                  // token: this.userModel.token,
                  activeUser: this.userModel.active,
                };
              }
            } else if (this.editConfirmPass !== this.confirmEditPass) {
              this.isEditError = true;
              this.errorMessage = "กรุณากรอกยืนยันรหัสผ่านให้ถูกต้อง";
              return;
            }
          } else {
            if (this.confirmPassword == this.password) {

              console.log("saveModel", saveModel);

              saveModel = {
                // userCode: this.userModel.userCode,
                username: this.userModel.username,
                password: this.password,
                fullname: this.userModel.fullname,
                // firstName: this.userModel.firstName,
                // lastName: this.userModel.lastName,
                phone: this.userModel.phone,
                // mobile: this.userModel.mobile,
                roleId: "94acdef8-e730-4d16-d3a2-08dae04b5516", // ตัวแทนขาย //this.userModel.roleId,
                // activeUser: this.userModel.activeUser,
                // roleName: 'ตัวแทนขาย',
                userType: (this.userType == null ? this.userModel.userType : this.userType.value) || this.userModel.userType, // 'แอดมิน'
                // token: "",
              };

              console.log("saveModel", saveModel);
              store
                .dispatch("user-list/addUser", saveModel)
                .then((response) => {
                  if (response.status === 200) {
                    this.$router
                      .push({ name: "admin-members-list" })
                      .then(() => {
                        this.showToast(
                          "success",
                          "CheckIcon",
                          "Your Workhas been saved"
                        );
                      });
                  }
                })
                .catch((error) => {
                  this.showToast("danger", "XCircleIcon", error);
                });
              return;
            } else {
              this.isError - true;
              this.errorMessage = "กรุณากรอกยืนยันรหัสผ่านให้ถูกต้อง";
              return;
            }
          }
          console.log("saveModel", saveModel);
          console.log("saveModel", JSON.stringify(saveModel));

          store
            .dispatch("user-list/updateUser", saveModel)
            .then((response) => {
              if (response.status === 200) {
                this.$router.push({ name: "admin-members-list" }).then(() => {
                  this.showToast(
                    "success",
                    "CheckIcon",
                    "Your Workhas been saved"
                  );
                });
              }
            })
            .catch((error) => {
              this.showToast("danger", "XCircleIcon", error);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
