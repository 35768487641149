<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>แสดง</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>รายการ</label>
          </b-col>

          <!-- Search todo-->
         
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative fix-minHeight-table"
        :items="fetchTranfer"
        responsive
        :fields="
          selectedRole == 'แม่ทีม' || selectedRole == 'พนักงานขาย'
            ? tableTranfer
            : tableTranfer
        "
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="ไม่พบข้อมูล"
        :sort-desc.sync="isSortDirDesc"
      >
       
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              แสดง
              {{ dataMeta.to }}
              จาก
              {{ dataMeta.of }}
              รายการ
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  
  
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BModal,
  VBModal,
  BFormGroup,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useUsersList from "./useOrdersList";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { getUserData } from "@/auth/utils";
import router from "@/router";
import axios, { baseURL } from "@axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BModal,
    VBModal,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    contractId: {
      type: String,
    },
    selectedRole: {
      type: String,
    },
  },
  data() {
    return {
      now: new date(),
    };
  },
  setup() {
    const SALE_ORDER_STORE_MODULE_NAME = "saleOrderMember";

    // Register module
    // if (!store.hasModule(SALE_ORDER_STORE_MODULE_NAME))
    //   store.registerModule(SALE_ORDER_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(USER_APP_STORE_MODULE_NAME))
    //     store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    // });

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    const optionsBrand = ref([]);
    const fetchBrands = () => {
      store
        .dispatch("user-list/fetchGetListSubInventoryByUserId")
        .then((response) => {
          console.log("response", response);
          if (response !== null) {
            response.data.data.forEach((data) => {
              optionsBrand.value.push({
                value: data.productId,
                label: data.productCode,
                realBalance: data.sumStockPartner - data.realSale,
                realBalance2: data.realBalance,
              });
            });
          }
        });
    };
    const selectedBrand = ref({});
    const optionsBrand2 = ref([]);
    const selectedBrand2 = ref({});
    fetchBrands();
    const qty = ref(0);
    const qty2 = ref(0);
    const realBalance = ref(0);
    const realBalance2 = ref(0);

    const {
      fetchUsers,
      fetchTranfer,
      tableColumns,
      tableColumns2,
      tableTranfer,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      fetchTranfer,
      tableColumns,
      tableColumns2,
      tableTranfer,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      optionsBrand,
      selectedBrand,
      optionsBrand2,
      selectedBrand2,
      qty,
      qty2,
      realBalance,
      realBalance2,
      
    };
  },
  mounted() {
    this.searchQuery = this.contractId;
    this.fetchUserTranfer();
  },
  methods: {
    showToast(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon,
          text,
          variant,
        },
      });
    },

    setData() {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      this.now = today.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
      });
      this.selectedBrand = null;
      this.qty = 0;
      this.selectedBrand2 = null;
      this.qty2 = 0;
      this.realBalance = 0;
      this.realBalance2 = 0;
    },

    setDataProduct(data) {
      console.log(data);
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      this.now = today.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
      });
      this.selectedBrand = null;
      this.qty = 0;
      this.selectedBrand2 = null;
      this.qty2 = 0;
      this.productname = data.item.productCode;
      this.productid = data.item.productId;
      this.realBalance =
        Number(data.item.sumStockPartner || 0) -
        Number(data.item.realSale || 0);
      this.realBalance2 = 0;
    },

    handleModalOk() {
      this.$emit("close");
      this.showToast("success", "CheckIcon", "Your Workhas been saved");
    },
    fetchProduct() {
      // optionsBrand
      this.realBalance =
        this.optionsBrand[
          this.optionsBrand.findIndex(
            (t) => t.value === this.selectedBrand.value
          )
        ].realBalance;

      store
        .dispatch("user-list/fetchSubInventoryByExchange", {
          id: this.selectedBrand.value,
        })
        .then((response) => {
          if (response !== null) {
            console.log("response2", response);
            this.optionsBrand2 = [];
            response.data.data.forEach((data) => {
              this.optionsBrand2.push({
                value: data.productId,
                label: data.productCode,
                realBalance : data.realBalance 
              });
            });
          }
        });
    },

    ChangeProductTranfer(productId,qtyTransfer,userIdTransfer) {
      console.log("ChangeProductTranfer");
      const userData = getUserData();

      var data = {
        userId : router.currentRoute.params.id,
        productIdTransfer : productId,
        qtyTransfer : qtyTransfer,
        userIdTransfer : userIdTransfer.value
      };

      console.log(data);

      axios
        .post(
          `${baseURL}/api/SubInventory/TransferSubInventory`,
          data
        )
        .then((response) => {
          console.log(response);
      
          this.showToast(
                        "success",
                        "CheckIcon",
                        "Your Work has been saved"
                      );
                      //this.$store.dispatch('user-list/fetchMyProducts');
                      this.$refs.refUserListTable.refresh();
   
          
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchUserTranfer() {
      store
        .dispatch("user-list/fetchUserTranfer", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          if (response !== null) {
            console.log("fetchUserTranfer", response);
            this.optionsUser = [];
            response.data.data.forEach((data) => {
              this.optionsUser.push({
                value: data.userId,
                label: data.username,
              });
            });
          }
        });
    },

    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          var saveModel = {
            productIdReceive: this.selectedBrand2.value,
            qtyReceive: this.qty2,
            productIdTransfer: this.selectedBrand.value,
            qtyTransfer: this.qty,
            userId: router.currentRoute.params.id,
          };

          console.log("saveModel", saveModel);
          console.log("saveModel", JSON.stringify(saveModel));

          store
            .dispatch("user-list/addExchangeSub", saveModel)
            .then((response) => {
              if (response.status === 200) {
                this.$emit("close");
                this.refetchData();
                this.showToast(
                  "success",
                  "CheckIcon",
                  "Your Workhas been saved"
                );
              }
            })
            .catch((error) => {
              this.showToast("danger", "XCircleIcon", error);
            });
        }
      });
    },
    checkQty() {
      console.log("checkQty");
      setTimeout(() => {
        console.log("qty", this.qty);
        console.log("realBalance", this.realBalance);

        if (parseInt(this.qty) > parseInt(this.realBalance)) {
          this.qty = parseInt(this.realBalance);
        }
      }, 100);
    },
    checkQty2() {
      console.log("checkQty2");
      setTimeout(() => {
        console.log("qty2", this.qty);
        console.log("realBalance2", this.realBalance);

        if (parseInt(this.qty2) > parseInt(this.realBalance2)) {
          this.qty2 = parseInt(this.realBalance);
        } else if (parseInt(this.qty2) > parseInt(this.qty)) {
          this.qty2 = parseInt(this.qty);
        }
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.fix-minHeight-table {
  min-height: 15rem;
}
</style>
